button {
  border-radius: 10px !important;
}

.btn-over-accordion {
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 9999;
  &.received {
    left: 175px;
    top: 18px;
    width: 40px;
  }
  &.rejected {
    left: 205px;
    top: 18px;
    width: 40px;
  }
  &.accepted {
    left: 235px;
    top: 18px;
    width: 40px;
  }
  &.hired {
    border-radius: 100%!important;
    left: 18.2rem;
    top: 15px;
    width: 40px;
    button {
      div {
        margin: 0 .2rem!important;
      }
    }
  }
  .status {
    padding: 0;
    font-size: 0.7rem;
  }

  @media (min-width: 768px) {
    &.received {
      top: 14px;
    }
    &.rejected {
      top: 14px;
    }
    &.accepted {
      top: 14px;
    }
  }
}

.btn-over-delegations {
  position: relative;
  align-items: center;
  display: flex;
  &.delegation {
    border-radius: 100%!important;
    button {
      div {
        margin: 0 .2rem!important;
      }
    }
  }
  .status {
    padding: 0;
    font-size: 0.7rem;
    display: block;
  }
}

.btn-over-accordion-hired {
  position: absolute;
  padding: 0;
  font-size: 0.5rem;
  right: 5.5rem;
  z-index: 9999;
  &.accepted {
    border-radius: 100%!important;
    left: 19rem;
    top: 1.55rem;
    width: 40px;
    button {
      div {
        margin: 0 .2rem!important;
      }
    }
  }
}

.p-0 {
  padding: 0px!important;
}

.text-success-hired {
  position: absolute;
  left: 15.2rem;
  top: 1.70rem;
  z-index: 9999;
}

.btn-delegations {
  align-items: center;
  display: flex!important;
  height: 1rem;
}
