//@import "index.css";
//@import "jefedeobra";

$primary: #1348ad;
$secondary: #84858a;
$success: #3e8d63;
$info: #009bfc;
$warning: #fdf023;
$danger: #b40012;
$light: #f8f9fa;
$dark: #343a40;
$accent : #da6d25;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  accent: $accent,
);

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";


@import "main";
