@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,400&display=swap");

$heightFooter: 180px;
$heightNavBar: 74px;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  width: auto;
}

h4 {
  font-size: 1.5rem;
  color: rgb(20, 20, 20);
  font-weight: 700 !important;
  text-transform: uppercase;

  &.light {
    color: #fff;
  }

  &.dark {
    color: #000;
  }

  &.blue {
    color: #fff;
  }
}

h5 {
  font-size: 1.3rem;
}

h6 {
  font-size: 1rem;
  color: #fff;
  font-weight: 100 !important;
}

small {
  font-size: 0.7rem;
}

.contrast-logo {
  font-size: 2rem;
  color: white;
}

.logo {
  font-size: 2rem;
  color: $primary;
}

#root {
  min-height: 100%;
  position: relative;
}

.table {
  font-size: 0.7rem;
  margin: 0;

  &.table-bordered > :not(caption) > * {
    background-color: #fff;
  }
}

.avatar {
  width: 40px;
  height: 40px;
}

header {
  position: relative;
  z-index: 999;
  height: 74px;

  #navbarScroll {
    text-align: center;

    .action-buttons {
      justify-content: center;
    }
  }
}

.btn-secondary {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .btn-primary:hover {
    color: #fff;
    background-color: #1348ad;
    border-color: #1348ad;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #84858a;
    border-color: #84858a;
  }
  .btn-outline-primary:hover {
    color: #1348ad;
    border-color: #1348ad;
    background-color: transparent;
  }

  .btn-outline-success:hover {
    color: #3e8d63;
    border-color: #3e8d63;
    background-color: transparent;
  }
  .btn-outline-success-hired:hover {
    color: #96ca6e;
    border-color: #96ca6e;
    background-color: transparent;
  }
  .btn-outline-danger:hover {
    color: #b40012;
    border-color: #b40012;
    background-color: transparent;
  }
}

.btn-outline-success {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}
.btn-outline-success-hired {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}
.btn-outline-danger {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}

.btn-success {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}

.btn-success-delegations {
  display: flex;
  vertical-align: middle;
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}

.btn-success-hired {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}
.btn-danger {
  margin-left: .5rem!important;
  margin-right: .5rem!important;
}

.card {
  z-index: 99;
  border-radius: 15px;
  background-color: #f8f9fa;
}

.form-group {
  margin-top: 0.8rem;
  .form-label {
    font-size: 0.8rem;
  }
}
.panel-home {
  .container {
    z-index: 99;
  }
}
.content {
  min-height: calc(100vh - 291px);

  &.panel-in {
    min-height: calc(100vh - 106px);
    background: #000;

    &:before {
      content: " ";
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0.6;
      background-image: url("../../assets/img/main-background.jpg");
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: 100% 100%;
      min-width: 1400px;
      @media (min-width: 992px) {
        background-size: 100% 100vh;
      }
    }
    .container {
      position: relative;
      z-index: 99;
    }

    .accordion {
      .accordion-item {
        position: relative;
      }
    }
  }
}

.modal-content {
  border-radius: 15px;
  button {
    &.close {
      background: none;
      border: none;
    }
  }
}

.loading-content {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  padding: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 9999999;
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  align-content: center;
  align-items: center;
  top: 0;

  .loading {
    width: 8rem;
    height: 4rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    position: relative;
    z-index: 99999999;
    align-items: center;
  }
}

.pagination {
  .page-link {
    min-width: 2.3rem !important;
    padding: 0.75rem 0.5rem !important;
    @media (min-width: 576px) {
      padding: 0.75rem 1rem;
      min-width: 3.5rem;
    }
  }
}

.breadcrumb {
  .breadcrumb-item {
    &.active {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}

.search-component {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  .input-search {
    width: 100%;
    @media (min-width: 768px) {
      width: calc(100% - 240px);
    }
  }

  .buttons-search {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: center;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}

.min-47 {
  min-width: 47px !important;
}

.item-list {
  padding: 10px;
  background-color: #f8f9fa;
  overflow: hidden;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  border-radius: 15px;
  height: 100%;

  &:nth-child(even) {
    background-color: #e9e8e8;
  }
}

.ofertas-proveedor {
  padding: 20px;
  z-index: 99;
  position: relative;
  min-width: 1400px;
  background-color: rgb(78, 96, 123);
  @media (min-width: 1400px) {
    background-color: transparent;
  }
  .proveedores-filtro {
    .item-filtro {
      padding: 2px;
      cursor: pointer;
      font-size: 10px;
      color: gray;

      &.selected {
        background-color: rgb(173, 216, 230);
      }
    }
  }
}

.item-list {
  margin-top: 15px;

  .category-box {
    background-color: $primary;
    color: white;
    display: flex;
    align-content: center;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 10px;
    justify-content: flex-start;
    border-radius: 10px;

    &.is-small {
      min-width: 215px !important;
    }

    &.is-medium {
      min-width: 300px !important;
    }
  }

  .category-name {
    font-size: 0.9rem;
  }
}

section {
  width: 100%;
  position: relative;

  &.buscador {
    width: 100%;
    background: transparent; /* For 100% screen width */

    &.panel-in {
      @media (min-width: 992px) {
        height: auto;
      }

      .container {
        padding: 0;

        .content-search {
          @media (min-width: 992px) {
            height: auto;
          }

          .middle-search {
            @media (min-width: 992px) {
              height: auto;
            }

            .card {
              padding: 0 !important;
              border-radius: 0 !important;
              border: none !important;
              background-color: #f8f9fa !important;

              .card-body {
                padding: 0 !important;
              }
            }
          }
        }
      }

      &:before {
        content: none;

        @media (min-width: 992px) {
          background-size: auto;
        }
      }
    }

    &.con-tabla {
      &:before {
        @media (min-width: 992px) {
          background-size: 100% 100%;
        }
      }

      @media (min-width: 992px) {
        height: auto;
        display: flex;
      }

      .content-search {
        @media (min-width: 992px) {
          height: 100% !important;
        }

        .middle-search {
          @media (min-width: 992px) {
            height: 100% !important;
            display: flex;
          }

          table {
            tr {
              th:first-child {
                min-width: 280px;
              }
            }
          }
        }
      }
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.6;
      // background-image: url("../../assets/img/main-background.jpg");
      // background-repeat: no-repeat;
      // background-position: 50% 0;
      // background-size: 100% 100vh;
    }

    .container {
      @media (min-width: 1400px) {
        max-width: 1450px !important;
      }

      .content-search {
        position: relative;
        z-index: 990;
        @media (min-width: 992px) {
          height: calc(100vh - #{$heightNavBar});
        }

        .middle-search {
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 992px) {
            height: calc(100vh - #{$heightNavBar});
          }

          .card {
            width: 100%;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.8);

            .card-body {
              z-index: 999;
              display: flex;
              justify-content: center;
              flex-direction: column;

              .card-title {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &.registro {
    background: rgb(24, 25, 54);
    padding-top: 50px;
    padding-bottom: 50px;
    color: #fff;

    .subtitulo {
      font-size: 1.3rem;
      font-style: italic;
    }
  }

  &.page-section {
    top: 74px;
    margin-bottom: 74px;

    .card {
      .icon-element {
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;

        &.color-yellowgreen {
          background-color: yellowgreen;
          font-size: 3.5rem;
        }

        &.color-blue {
          background-color: $primary;
          font-size: 3.5rem;
        }

        &.color-lightcoral {
          background-color: lightcoral;
          font-size: 3.5rem;
        }

        &.color-dodgerblue {
          background-color: dodgerblue;
          font-size: 3.5rem;
        }

        &.color-orange {
          background-color: orange;
          font-size: 3.5rem;
        }
      }
    }
  }

  &.contact {
    background: rgb(110, 110, 110);
    color: #fff;
  }

  &.enlaces {
    background: rgba(190, 190, 190, 0.7);
    color: #000;
  }

  &.partners {
    padding-top: 50px;
    padding-bottom: 120px;
    background-color: #fff;
    .logo-partners {
      align-self: flex-end;

      a {
        text-align: center;

        img {
          /*height: 50px;
          max-width: 100%;*/
          height: auto;
          max-width: 100%;
          width: 200px;
          margin-bottom: 70px;
          @media (min-width: 768px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.solicitud-oferta-proveedores {
    .container {
      @media (min-width: 1200px) {
        max-width: 90%;
      }

      .card {
        background-color: #f8f9fa;
        border: none;

        .accordion {
          padding: 2rem;

          .ubicacion {
            .form-check {
              width: 185px;
            }
          }
        }
      }
    }
  }
}

footer {
  color: white;
  background: rgb(24, 25, 54);
  width: 100%;
  position: relative;
  background-attachment: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;

  .link-content {
    background: rgb(24, 25, 54);
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }

    a {
      color: #fff;
      font-size: 0.8rem;
      font-weight: 700;
      display: block;
      padding-top: 10px;
      text-decoration: none;

      @media (min-width: 768px) {
        padding-right: 15px;
      }

      &.social-network {
        display: inline;
        font-size: 2rem;
        margin-left: 10px;
        @media (min-width: 768px) {
          margin-left: 0;
        }
      }
    }
  }

  .copyright {
    padding-top: 6px;
    height: 32px;
    color: #fff;
    text-align: center;
    background: rgb(16, 13, 14);
    font-size: 0.8rem;
  }
}

.container {
  padding: 12px;
}

.tag-item {
  padding: 7px 10px;
  margin: 5px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
}

.parallax {
  background-image: url("../img/backgroud-paralax.jpeg");
  min-height: 380px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.category-item {
  background-color: rgba(220, 220, 220, 0.3);
  border-radius: 5px;
  line-height: 16px;
  padding-top: 2px;
  padding-right: 3px;
  padding-left: 2px;
  margin: 3px;
  display: inline-block;
  font-size: 0.6rem;
}

.legal-text {
  text-align: justify;
}

.about-image {
  width: 253px;
  height: 250px;
  background-color: rgb(250, 250, 250);
  margin: 10px auto;
  padding-top: 50px;
}

.text-no-image {
  margin-top: 8px;
  font-size: 13px;
}

.table-item-title {
  font-size: 16px;
  font-weight: 400;
}

.table-item-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: #686868;
}

.divider-custom {
  border-top: 1px solid #e0e0e0;
  margin: 10px 0;
  padding: 4px;
  line-height: 16.8px;
  font-size: 0.7rem;
  cursor: pointer;
}
.table > :not(:first-child) {
  border-top: 2px solid rgba(122,122,122,.7);
  thead {
    tr {
      border-width: 0 !important;
    }
    th {
      border-width: 0 !important;
    }
  }
  td {
    border-width: 0;
  }
}
.panel-home {
  background: #000 !important;
  top: 0px !important;
  margin-bottom: 0px !important;
  height: 100%!important;
  z-index: 2;
  &:before {
    content: " ";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.6;
    background-image: url("../../assets/img/main-background.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 100% 100%;
    // min-width: 1400px;
    @media (min-width: 992px) {
      background-size: 100% 100vh;
    }
  }
}
.index-2 {
  z-index: 2;
}

.customDropDown {
  button {
    margin: 0px;
    padding: .1rem .5rem;
  }
}

.background-gray {
  background-color: gray!important;
}

.radius-100 {
  border-radius: 100%!important;
}

.radius-2 {
  border-radius: 2rem!important;
}

.margin-0 {
  margin: 0!important;
}

.padding-0 {
  padding: 0rem!important;
}

.padding-right-0 {
  padding-right: 0rem!important;
}

.padding-left-0 {
  padding-left: 0rem!important;
}

.move-left-05 {
  left: 0.5rem;
}

.margin-left-0 {
  margin-left: 0!important;
}

.move-right-05 {
  right: 0.5rem;
}

.position-relative {
  position: relative;
}

.field-gray {
  background-color: rgba(0, 0, 0, 0.05);
}

.field-white {
  background-color: white;
}

.vertical-align-middle {
  vertical-align: middle;
}

.padding-button {
  padding: 0rem 0.4rem!important;
}

.padding-save-button {
  padding: 0rem 0.4rem!important;
}

.display-flex {
  display: flex!important;
}

.align-content-center {
  align-content: center!important;
}

.justify-content-center {
  justify-content: center!important;
}

.justify-content-end {
  justify-content: end;
}

.font-weight-bold {
  font-weight: bold;
}

.text-active {
  color: var(--bs-accordion-active-color)!important;
}

.color-dark-orange {
  color: darkorange;
}

.color-dark-red {
  color: darkred;
}

.color-green {
  color: #3e8d63;
}

.color-red {
  color: #b40012;
}

.width-1-2 {
  input {
    width: 1.2rem;
  }
}

.width-100 {
  width: 100%!important;
}

.width-90 {
  width: 90%!important;
}

.height-1-2 {
  input {
    height: 1.2rem;
  }
}

.comment-background {
  background-color: white;
}

.comments-counter {
  position: absolute;
  top: .5rem;
  right: 6rem;
  z-index: 9999;
  font-size: 1rem;
}
